
































































































import { AccessType } from '@/constants'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Inject } from 'vue-property-decorator'
import CardHover from '../../../components/card-hover.vue'
import { PoolStore } from '../stores/pool-store'
import { AppProvider } from '@/app-providers'
import CountdownPoolState from './countdown-pool-state.vue'

@Observer
@Component({ components: { CountdownPoolState, CardHover } })
export default class UpcomingPoolItem extends Vue {
  @Inject() providers!: AppProvider
  @Prop({ required: true, default: null }) model!: PoolStore

  mounted() {
    // this.model.loadDataIfNeed()
  }

  clickOnCard() {
    if (this.model.web) {
      window.open(this.model.web)
    }
  }
  get isWhitelist() {
    return this.model.pool?.accessType === AccessType.Whitelist
  }
}
